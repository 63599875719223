<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @keyup.enter="onFilter()"/>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light me-3" @click="refreshMenu()">Refresh</button>
                        <button class="btn btn-primary" @click="modalClick('add', null)">Create</button>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Nama</th>
                            <th>Parent</th>
                            <th>Route</th>
                            <th>Ikon</th>
                            <th>Urutan</th>
                            <th>Status</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.parent || '-' }}</td>
                            <td>{{ item.route }}</td>
                            <td>{{ item.icon }}</td>
                            <td>{{ item.sequence }}</td>
                            <td>
                              <span class="badge badge-danger" v-if="item.active == 0">Non Active</span>
                              <span class="badge badge-success" v-if="item.active == 1">Active</span>
                            </td>
                            <td scope="col" class="text-end w-150px">
                                <div class="d-flex justify-content-end">
                                    <button @click="modalClick('edit', item)" class="btn btn-secondary btn-sm me-1" title="Edit Menu">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <el-popconfirm
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Are you sure to delete this?"
                                        @confirm="onDelete(item)"
                                    >
                                        <template #reference>
                                            <a href="javascript:void(0)" class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>

        <el-dialog v-model="modal" :title="modalTitle" width="600px" :close-on-click-modal="false">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 my-5"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled/>
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Nama</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="title"
                        :rules="[
                          { required: true, message: 'Name is required' },
                        ]"
                      >
                        <el-input v-model="tempData.title" name="title" class="form-control p-0 border-0" placeholder="Masukkan nama" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Parent</label>
                        <el-form-item
                          class="fv-row mb-7"
                          prop="parent_id"
                          :rules="[
                            { required: true, message: 'Parent is required' },
                          ]"
                        >
                          <el-select name="parent_id" v-model="tempData.parent_id" class="form-control p-0 border-0" placeholder="Pilih parent menu" size="large" filterable>
                            <el-option
                              :label="'Tidak Ada'"
                              :value="0"
                            >
                              <div class="d-flex">
                                <div class="col me-3">Tidak Ada</div>
                              </div>
                            </el-option>
                            <el-option
                              v-for="(item, i) in listParentMenu"
                              :key="i"
                              :label="item.title"
                              :value="item.id"
                            >
                              <div class="d-flex">
                                <div class="col me-3">{{ item.title }}</div>
                              </div>
                            </el-option>
                          </el-select>
                        </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Route</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="route"
                        :rules="[
                          { required: true, message: 'Route is required' },
                        ]"
                      >
                        <el-input v-model="tempData.route" name="route" class="form-control p-0 border-0" placeholder="Url routing" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Urutan</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="sequence"
                        :rules="[
                          { required: true, message: 'Sequence is required' },
                        ]"
                      >
                        <el-input v-model="tempData.sequence" name="sequence" class="form-control p-0 border-0" placeholder="Urutan menu" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Aktif</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="active"
                        :rules="[
                          { required: true, message: 'Active is required' },
                        ]"
                      >
                        <el-radio-group v-model="tempData.active">
                          <el-radio :label="1" size="large">Ya</el-radio>
                          <el-radio :label="0" size="large">Tidak</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="button"
                        ref="submitButton"  
                        class="btn btn-primary"
                        :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                        @click="onSubmit(formRef, submitType)"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination, FormInstance, FormRules } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        //Datatable,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const loadingSubmit = ref(false);
        const tableRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })

        const formRef = ref(null)

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const listParentMenu = reactive([])

        const tempData = reactive({
            id: null,
            title: null,
            parent_id: null,
            route: null,
            sequence: null,
            active: null,
        })
        
        const submitType = ref('')

        const modal = ref(false)
        const modalTitle = ref('')
        
        const modalClick = async(type, data = null) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                submitType.value = type

                tempData.id = null
                tempData.title = ''
                tempData.parent_id = ''
                tempData.route = ''
                tempData.icon = ''
                tempData.sequence = ''
                tempData.active = ''

                await parentMenu()

                if(type == 'add') {
                    modalTitle.value = 'Add New Menu'
                    tempData.active = 1
                }
                if(type == 'edit') {
                    console.log(data)
                    await Object.assign(tempData, data);
                    modalTitle.value = 'Edit Menu'
                }

                modal.value = true

            } catch(e) {

                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const onFilter = async (type) => {
            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const parentMenu = async () => {
            try {
                await listParentMenu.splice(0)

                await ApiService.get("parent_menu")
                .then(async ({ data }) => {
                    Object.assign(listParentMenu, data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting parent menu');
                });

            } catch(e) {
                console.error(e)
            }
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let search = filter.search ? '&search='+filter.search : '';

                await ApiService.get("menu_management?page=" + page + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting menu management');
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const onSubmit = async(formEl, type) => {
            try {
                if (!formEl) return

                await formEl.validate( async(valid, fields) => {
                    loadingSubmit.value = true;

                    if(valid) {
                        let endpoint = '';

                        if(type == 'add') {
                            endpoint = '/menu/create';
                        }
                        if(type == 'edit') {
                            endpoint = '/menu/update/'+tempData.id;
                        }

                        await ApiService.post(endpoint, JSON.parse(JSON.stringify(tempData)))
                        .then(async({ data }) => {
                            if(data.error) {
                                throw new Error(data.messages)
                            }

                            modal.value = false

                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })

                            await getData()
                        })
                        .catch(({ response }) => {
                            if(response.data.messages) { 
                                if(typeof response.data.messages === 'object')
                                    throw Error(Object.entries(response.data.messages).map(([k, v]) => `${v}`).join(', \n'));
                                else 
                                    throw Error(response.data.messages);
                            }
                            
                        });

                    } 
                    
                    loadingSubmit.value = false;
                })

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const onDelete = async(row) => {
            try {
                await ApiService.post("menu/delete/"+row.id)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = data.logo
            viewImageVisible.value = true
            viewImageSrc.value = img_url_logo.value+data.logo
        }

        const refreshMenu = () => {
            try {
                store.dispatch(Actions.MENU);

                Swal.fire({
                    title: 'Refresh success',
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

            } catch(e) {
                console.error(e)
            }
        }

        watch(() => tempData.name, (val) => {
            if(val) {
                let splitName = val.split(" ");
                
                if(submitType.value == 'add') {
                    if(splitName.length > 1) {
                        tempData.shortname = splitName.join("_").toLowerCase();
                    } else {
                        tempData.shortname = val.toLowerCase();
                    }
                }
            }
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(route.meta.title, ['Menu', route.meta.title]);

            getData()
        })

        return {
            formatIDR,
            loadingTable, loadingSubmit, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            modal, modalTitle, modalClick,
            pagination, handlePageChange,
            onFilter, resetFilter, filter, filterActive,
            formRef,
            tableData, tempData, listParentMenu, submitType, onSubmit, onDelete,
            refreshMenu,
        }
    }
})
</script>